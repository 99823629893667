//******************************
// FrutigerLTCom-Light
//******************************
@font-face {
  font-family: 'FrutigerLTCom-Light';
  src: url('../fonts/FrutigerLTCom-Light.eot');
  src: url('../fonts/FrutigerLTCom-Light.woff2') format('woff2'),
  url('../fonts/FrutigerLTCom-Light.woff') format('woff'),
  url('../fonts/FrutigerLTCom-Light.ttf') format('truetype'),
  url('../fonts/FrutigerLTCom-Light.svg#FrutigerLTCom-Light') format('svg'),
  url('../fonts/FrutigerLTCom-Light.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



//******************************
// FrutigerLTCom-Roman
//******************************
@font-face {
  font-family: 'FrutigerLTCom-Roman';
  src: url('../fonts/FrutigerLTCom-Roman.eot');
  src: url('../fonts/FrutigerLTCom-Roman.woff2') format('woff2'),
  url('../fonts/FrutigerLTCom-Roman.woff') format('woff'),
  url('../fonts/FrutigerLTCom-Roman.ttf') format('truetype'),
  url('../fonts/FrutigerLTCom-Roman.svg#FrutigerLTCom-Roman') format('svg'),
  url('../fonts/FrutigerLTCom-Roman.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



//******************************
// FrutigerLTCom-Condensed
//******************************
@font-face {
  font-family: 'FrutigerLTCom-Condensed';
  src: url('../fonts/FrutigerLTCom-Condensed.eot');
  src: url('../fonts/FrutigerLTCom-Condensed.woff2') format('woff2'),
  url('../fonts/FrutigerLTCom-Condensed.woff') format('woff'),
  url('../fonts/FrutigerLTCom-Condensed.ttf') format('truetype'),
  url('../fonts/FrutigerLTCom-Condensed.svg#FrutigerLTCom-Condensed') format('svg'),
  url('../fonts/FrutigerLTCom-Condensed.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}



//******************************
// FrutigerLTCom-Bold
//******************************
@font-face {
  font-family: 'FrutigerLTCom-Bold';
  src: url('../fonts/FrutigerLTCom-Bold.eot');
  src: url('../fonts/FrutigerLTCom-Bold.woff2') format('woff2'),
  url('../fonts/FrutigerLTCom-Bold.woff') format('woff'),
  url('../fonts/FrutigerLTCom-Bold.ttf') format('truetype'),
  url('../fonts/FrutigerLTCom-Bold.svg#FrutigerLTCom-Bold') format('svg'),
  url('../fonts/FrutigerLTCom-Bold.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}


