.search {
  display: inline-block;
  vertical-align: top;
  margin: 3px 25px 5px 0;
}

#search_it_form {
  box-sizing: border-box;
  font-size: 1rem;
  font-family: sans-serif;
  display: flex;
  max-width: 50%;
  border-bottom: 1px solid $color-red;


  fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    width:100%;
  }

  legend, label {
    display: none;
  }

  input {
    font-size: $fontSize16;
    font-family: $font-roman;
    height: 34px;
    appearance: none;
    border: none;
    width: calc(100% - 33px);
    display: inline-block;
    margin-top: 55px;


    &:focus {
      outline: none;
    }
  }

  .button {
    appearance: none;
    width: 30px;
    height: 34px;
    border: none;
    cursor: pointer;
    display: inline-block;
    text-align: right;
    background-color: transparent;

    &:hover {
      padding-left: 0;
    }
  }

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: $color-placeholder;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: $color-placeholder;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: $color-placeholder;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: $color-placeholder;
  }

  input:focus::-webkit-input-placeholder{
    color: transparent;
  }

  input:focus::-moz-placeholder {
    color: transparent;
  }

  input:focus:-ms-input-placeholder {
    color: transparent;
  }

  input:focus:-moz-placeholder {
    color: transparent;
  }

}

.search_it-flex {
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;

  > * {
    flex: 2 2 200px;
  }

  > .search_it-button {
    flex: 1 1 100px;
  }
}

.search__wrapper {
  margin-top: 115px;

  &.search__title {
    width: 83.3%;
    border-bottom: 1px solid $color-grey-border;
    padding-bottom: 15px;
  }
}

.searchresults {
  margin-top: 45px;
  width: 83.3%;

  a,
  p {
    font-size: $fontSize16;
  }

  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}

.search__restult-item,
.download {
  display: inline-block;
}


