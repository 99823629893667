.grid {
  .container {
    display: flex;
    flex-wrap: wrap;
  }

  .col-3 {
    flex-grow: 1;
    width: 33%;
  }
}